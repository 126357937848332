<template>
    <div>
        <section class="hero py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h1 class="display-4">Erstklassige Entwicklung für Ihr Unternehmen</h1>
                        <p class="lead">Wir gestalten benutzerfreundliche und moderne Websites, die Ihre Marke
                            hervorheben und Ihre Zielgruppe ansprechen. Außerdem sind wir für Sie im Bereich der
                            Anwendungsentwicklung auch gerne Ihr Ansprechpartner.</p>
                        <a v-on:click="show('contact')" href="#contact" class="btn btn-gold btn-lg">Kontaktieren Sie uns</a>
                    </div>
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-4">
                        <img :src="require('@/assets/logo.png')" alt="Logo" class="img-fluid rounded-circle">
                    </div>
                </div>
            </div>
        </section>

        <section class="services py-5 bg-dark">
            <div class="container">
                <h2 class="text-center text-light mb-4">Unsere Leistungen</h2>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="card h-100 bg-dark text-light">
                            <div class="card-body">
                                <i class="fas fa-desktop fa-3x text-gold mb-3"></i>
                                <h5 class="card-title">Webdesign</h5>
                                <p class="card-text">Modernes und responsives Webdesign für Ihre Website.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card h-100 bg-dark text-light">
                            <div class="card-body">
                                <i class="fas fa-code fa-3x text-gold mb-3"></i>
                                <h5 class="card-title">Entwicklung</h5>
                                <p class="card-text">Professionelle Web- & Anwendungsentwicklung mit den neuesten Technologien. Dabei haben wir auch ein Auge auf die Performance.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card h-100 bg-dark text-light">
                            <div class="card-body">
                                <i class="fas fa-chart-line fa-3x text-gold mb-3"></i>
                                <h5 class="card-title">SEO-Optimierung</h5>
                                <p class="card-text">Optimierung Ihrer Website für eine bessere Sichtbarkeit in
                                    Suchmaschinen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Hier wird der Slider später eingefügt mit den Bildern zu Projekte, die umgesetzt wurden. -->

        <section class="workers py-5">
            <div class="container">
                <h2 class="text-center mb-4">Wer sind <span class="text-gold-light">WIR</span>?</h2>
                <div class="row">
                    <div class="col-md-2 mb-2"></div>
                    <div class="col-md-4 mb-4">
                        <div class="card">
                            <img :src="require('@/assets/workers/placeH.jpg')" class="card-img-top" alt="markusB">
                            <div class="card-body">
                                <h5 class="card-title">Markus Brünner</h5>
                                <p class="card-text h6">Entwickler</p>
                                <p class="card-text">Ob Anwendungsentwicklung oder Webdesign, das ist kein Problem. Seit 2014 bin ich in der Entwicklung tätig und hab unter anderem Projekte wie <a href="https://www.vTogether.de">vTogether</a>, <a href="https://www.IslandV.net">IslandV</a> aufgebaut und eigenständig entwickelt. Dazu gehört der Online Auftritt, die Entwicklung der Systeme in [C#, Javascript, PHP, VueJS], die Administration der Server und vieles mehr.</p>
                                <a v-on:click="show('markusB')" href="#markusB" class="btn btn-gold">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card">
                            <img :src="require('@/assets/workers/placeH.jpg')" class="card-img-top" alt="florianG">
                            <div class="card-body">
                                <h5 class="card-title">Florian Gäb</h5>
                                <p class="card-text h6">Brandbuilding, Marketing und CyberSecurity</p>
                                <p class="card-text">Ich bin 27 Jahre alt und meine Stärken in der IT liegen im Marketing, in der Marktanalyse, im Brandbuilding und im Customer Support. Mit einem ausgeprägten Gespür für Marktentwicklungen und Kundenbedürfnisse habe ich bereits erfolgreich einen eigenen Onlineshop aufgebaut, geführt und schließlich gewinnbringend verkauft. Meine Fähigkeit, effektive Marketingstrategien zu entwickeln und eine starke Markenpräsenz zu etablieren, kombiniert mit exzellentem Kundenservice, macht mich zu einem wertvollen Akteur in der IT-Branche.</p>
                                <a v-on:click="show('florianG')" href="#florianG" class="btn btn-gold">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mb-2"></div>
                </div>
            </div>
        </section>

        <section class="actions py-5">
            <div class="container">
                <h2 class="text-center mb-4">Warum sollten <span class="text-gold">SIE</span> uns wählen?</h2>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="card">
                            <img :src="require('@/assets/home/customer.jpg')" class="card-img-top" alt="Kundenorientierte Entwicklung">
                            <div class="card-body">
                                <h5 class="card-title">Kundenorientierte Arbeit</h5>
                                <p class="card-text">Bei uns stehen Sie als Kunde an erster Stelle. Wir hören genau hin, um Ihre spezifischen Anforderungen und Wünsche zu verstehen und maßgeschneiderte Lösungen zu bieten. Unser Ziel ist es, nicht nur Ihre Erwartungen zu erfüllen, sondern sie zu übertreffen. Durch eine enge Zusammenarbeit und klare Kommunikation schaffen wir eine vertrauensvolle Partnerschaft, in der Ihre Zufriedenheit unser größtes Anliegen ist.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card">
                            <img :src="require('@/assets/home/lowCost.jpg')" class="card-img-top" alt="Bezahlbare Preise">
                            <div class="card-body">
                                <h5 class="card-title">Bezahlbare Preise</h5>
                                <p class="card-text">Hochwertige Dienstleistungen müssen nicht teuer sein. Wir bieten Ihnen faire und transparente Preise, die zu Ihrem Budget passen. Bei uns können Sie sich darauf verlassen, dass es keine versteckten Kosten gibt. Qualität und Erschwinglichkeit gehen bei uns Hand in Hand, sodass Sie die besten Lösungen zum besten Preis erhalten.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="card">
                            <img :src="require('@/assets/home/testing.jpg')" class="card-img-top" alt="Gutes Testing">
                            <div class="card-body">
                                <h5 class="card-title">Ausgiebiges Testen</h5>
                                <p class="card-text">Bevor eine Lösung an Sie übergeben wird, unterziehen wir sie umfangreichen und detaillierten Tests. Unser Anspruch ist es, dass alles perfekt funktioniert, bevor es in den Einsatz geht. Durch unser sorgfältiges Testverfahren können Sie sich sicher sein, dass die Endergebnisse nicht nur funktional, sondern auch zuverlässig und robust sind. So garantieren wir Ihnen höchste Qualität und sorgen dafür, dass Ihre Projekte reibungslos laufen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "home",
        props: {
            data: String
        },
        data() {
            return {
            }
        },
        methods: { 
            show: function (window) {
                components.Framework.show(window)
            },
        },
        created: function () {
            
        },
        mounted: function () {

        },
        destroyed: function () {
        }
    })
</script>

<style>

</style>