<template>
    <div>
        <section class="hero py-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <h1 class="display-4">Unser Portfolio</h1>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4"><img src="img/logo.d0ca7e5e.png"
                            alt="Logo" class="img-fluid rounded-circle"></div>
                </div>
            </div>
        </section>
        
        <section class="py-5 bg-dark">
            <div class="container mt-5">
                <div class="row align-items-center">
                    <div class="col-md-10">
                        <h1 class="lead text-light">Hier finden Sie eine kleine Übersicht der Projekte die bereits umgesetzt wurden.
                        Aktuell haben wir 2 vollendete Projekte durchgeführt. Weitere Projekte werden natürlich folgen und
                        nach Absprache hier hinterlegt.</h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5 workers">
            <div class="container mt-5">
                <div class="row g-4">
                    <!-- Portfolio Box -->
                    <div class="col-md-4">
                    </div>
                    <!-- Portfolio Box -->
                    <div class="col-md-4">
                        <div class="portfolio-box">
                            <img :src="require('@/assets/portfolio/none.png')" class="img-fluid" alt="Bild 1">
                            <div class="overlay">
                                <div class="text">
                                    <h5>Weitere infos</h5>
                                    <p>Aktuell wurden nur eigene Projekte aufgebaut, die für Spiele entwickelt wurden. Sobald Unternehmen oder Personen ein Projekt umgesetzt haben möchte, wird
                                        dieses natürlich hier hinterlegt. Alternativ kann sich natürlich über "IslandV" oder "vTogether" informiert werden.
                                    </p>
                                    <a href="https://vTogether.de" target="_blank" class="btn btn-gold btn-lg">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Portfolio Box -->
                    <div class="col-md-4">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "portfolio",
        props: {
            data: String
        },
        data() {
            return {}
        },
        methods: {
            show: function (window) {
                components.Framework.show(window)
            },
        },
        created: function () {

        },
        mounted: function () {

        },
        destroyed: function () {}
    })
</script>

<style>

</style>